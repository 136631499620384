import React from "react"

import Layout from "../layout/index"
import SEO from "../components/seo"
import ProfileImg from "../images/eunicepark.jpeg"


const AboutPage = () => (
	<div id="wrapper" class="main">
	<SEO title="About" />
		<div id="main">
			<div class="inner">
				<h1>About</h1>
		        <div className="grid-wrapper">
		            <div className="col-6">
		            	<img class="image" src={ProfileImg}/>
		            </div>
		            <div className="col-6">
		                <p>
							<h3>Eunice Park</h3>
							<ul>
								<li><strong style={{color:'#836E3D'}}>2016</strong> ECOLE LENOTRE PARIS </li>
								<li><strong style={{color:'#836E3D'}}>2017</strong> Trained at Paris Ritz-Carlton Hotel</li>
								<li><strong style={{color:'#836E3D'}}>2017</strong> Founder of Treats by Eunice - Dessert Cafe & Baking Studio</li>
								<li><strong style={{color:'#836E3D'}}>2018~2019</strong> Classes at Shinsegae Dept Gangnam & Myeongdong</li>
								<li><strong style={{color:'#836E3D'}}>2019</strong> Intepreter for classes held by overseas chefs</li>
							</ul>
						</p>
		            </div>
		        </div>
			</div>
		</div>
	</div>
)

export default AboutPage;